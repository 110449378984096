import React, { useState, useEffect } from "react";
import "./NewMissionForm.css";
import axios from "axios";

const NewMissionForm = () => {
  const [selectedAffaire, setSelectedAffaire] = useState("");
  const [selectedFacturation, setSelectedFacturation] = useState("");
  const [selectedDomaines, setSelectedDomaines] = useState([]);
  const [domaineDetails, setDomaineDetails] = useState([]);
  const [formIsValid, setFormIsValid] = useState(false);
  const [formIsSent, setFormIsSent] = useState(false);
  const [totalPercentageError, setTotalPercentageError] = useState("");
  const [subPercentageError, setSubPercentageError] = useState("");
  const [selectedTypeMission, setSelectedTypeMission] = useState("");
  const [offersData, setOffersData] = useState([]);
  const [missionsData, setMissionsData] = useState([]);

  const clearAllFields = () => {
    setSelectedAffaire("");
    setSelectedFacturation("");
    setSelectedDomaines([]);
    setDomaineDetails([]);
    setFormIsValid(false);
    setTotalPercentageError("");
    setSubPercentageError("");
    setSelectedTypeMission("");
  };

  const removeMissionFromData = (affaire) => {
    setMissionsData((prevMissionsData) =>
      prevMissionsData.filter((mission) => mission.Affaire !== affaire)
    );
  };

  useEffect(() => {
    const backendUrl =
      "https://us-central1-dataplaform-iac.cloudfunctions.net/readFiles";

    axios
      .get(backendUrl + "/offers-json", { responseType: "text" })
      .then((response) => {
        console.log(response.data);
        const jsonObj = JSON.parse(response.data);
        setOffersData(jsonObj);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des données de offers.csv :",
          error
        );
      });

    axios
      .get(backendUrl + "/new-missions-csv", { responseType: "text" })
      .then((response) => {
        const jsonObj = JSON.parse(response.data);
        setMissionsData(jsonObj);
      })
      .catch((error) => {
        console.error(
          "Erreur lors de la récupération des données de new_missions.csv :",
          error
        );
      });
  }, []);

  const getBlockOptionsForDomaine = (domaine) => {
    const domainOffers = offersData.filter(
      (offer) => offer.Domaine === domaine
    );
    const uniqueBlocks = [...new Set(domainOffers.map((offer) => offer.Offre))];
    return uniqueBlocks;
  };

  const handleAffaireChange = (event) => {
    setSelectedAffaire(event.target.value);
  };

  const handleFacturationChange = (event) => {
    setSelectedFacturation(event.target.value);
  };

  const handleTypeMissionChange = (event) => {
    setSelectedTypeMission(event.target.value);
  };

  const handleDomaineChange = (event) => {
    const selectedDomaine = event.target.value;
    setSelectedDomaines((prevDomaines) =>
      prevDomaines.includes(selectedDomaine)
        ? prevDomaines.filter((domaine) => domaine !== selectedDomaine)
        : [...prevDomaines, selectedDomaine]
    );
  };

  const handleDomaineDetailsChange = (domaine, type, value) => {
    const updatedDomaineDetails = [...domaineDetails];
    const index = updatedDomaineDetails.findIndex(
      (detail) => detail.domaine === domaine
    );
    if (index !== -1) {
      updatedDomaineDetails[index][type] = value;
    } else {
      updatedDomaineDetails.push({ domaine, [type]: value });
    }
    setDomaineDetails(updatedDomaineDetails);
  };

  const validateDomaineDetails = () => {
    let isValid = true;

    selectedDomaines.forEach((domaine) => {
      const domaineDetailsForDomaine = domaineDetails.filter(
        (detail) => detail.domaine === domaine
      );
      const totalPercentageOffrePrincipale = domaineDetailsForDomaine.reduce(
        (sum, detail) => sum + Number(detail.percentageOffrePrincipale || 0),
        0
      );
      const totalPercentageOffreSecondaire = domaineDetailsForDomaine.reduce(
        (sum, detail) => sum + Number(detail.percentageOffreSecondaire || 0),
        0
      );

      if (
        totalPercentageOffrePrincipale + totalPercentageOffreSecondaire !==
        100
      ) {
        setSubPercentageError(
          `La somme des pourcentages pour le domaine ${domaine} doit être égale à 100%.`
        );
        isValid = false;
      }
    });

    return isValid;
  };

  const PROD_Update_New_Mission_URL =
    "https://europe-west2-dataplaform-iac.cloudfunctions.net/PROD_Update_New_Mission";

  const handleSubmit = async (event) => {
    event.preventDefault();

    let isValid = true;

    const totalPercentage = domaineDetails.reduce(
      (sum, detail) => sum + Number(detail.percentage),
      0
    );
    if (totalPercentage !== 100) {
      setTotalPercentageError(
        "La somme des pourcentages doit être égale à 100%."
      );
      alert(
        "La somme des pourcentages des CA des domaines doit être égale à 100%."
      );
      isValid = false;
    } else {
      setTotalPercentageError("");
    }

    if (!validateDomaineDetails()) {
      isValid = false;
    } else {
      setSubPercentageError("");
    }

    setFormIsValid(isValid);

    if (isValid) {
      try {
        const backendUrl =
          "https://us-central1-dataplaform-iac.cloudfunctions.net/readFiles";
        const dataToSubmit = {
          affaire: selectedAffaire,
          facturation: selectedFacturation,
          typeMission: selectedTypeMission,
        };

        domaineDetails.forEach((detail) => {
          if (detail.percentage) {
            dataToSubmit[`percentage_${detail.domaine}`] = detail.percentage;
          }
          if (detail.offrePrincipale) {
            dataToSubmit[`offrePrincipale_${detail.domaine}`] =
              detail.offrePrincipale;
          }
          if (detail.percentageOffrePrincipale) {
            dataToSubmit[`percentageOffrePrincipale_${detail.domaine}`] =
              detail.percentageOffrePrincipale;
          }
          if (detail.offreSecondaire) {
            dataToSubmit[`offreSecondaire_${detail.domaine}`] =
              detail.offreSecondaire;
          }
          if (detail.percentageOffreSecondaire) {
            dataToSubmit[`percentageOffreSecondaire_${detail.domaine}`] =
              detail.percentageOffreSecondaire;
          }
        });

        const insertResponse = await axios.post(
          backendUrl + "/insertintoBigQuery",
          dataToSubmit
        );

        if (insertResponse.status === 200) {
          setFormIsSent(true);
          removeMissionFromData(selectedAffaire);
          clearAllFields();

          axios
            .post(
              backendUrl + "/remove-mission-from-csv",
              { affaire: selectedAffaire },
              { responseType: "text" }
            )
            .then((response) => {
              if (response.status === 200) {
                console.log(
                  "La mise à jour de new-missions.csv a été effectuée"
                );
              } else {
                console.warn(
                  "La mise à jour de new-missions.csv a échouée",
                  response.status
                );
              }
            })
            .catch((error) => {
              console.error(
                "Erreur lors de la suppression de l'affaire sur GCP",
                error
              );
            });

          alert("Données insérées avec succès dans BigQuery !");
        } else {
          console.error("Erreur : ", insertResponse.statusText);
          alert(
            "Une erreur est survenue lors de l'insertion des données dans BigQuery."
          );
        }
      } catch (error) {
        console.error("Erreur lors de l'insertion dans BigQuery:", error);
        alert("Une erreur est survenue lors de l'insertion des données.");
      }
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        backgroundColor: "#ffffff",
        border: "1px solid #ccc",
        borderRadius: "10px",
        padding: "50px",
      }}
    >
      {formIsSent && (
        <div
          className="formValidatedPopUp"
          onClick={() => setFormIsSent(false)}
        >
          Le formulaire est validé.
        </div>
      )}
      <h1 style={{ color: "#53bccb", textAlign: "center" }}>
        Formulaire nouvelles missions
      </h1>
      <p
        style={{
          color: "red",
          fontWeight: "bolder",
        }}
      >
        Attention : Vous êtes responsable des informations que vous saisissez.
        <br />
        Une fois le formulaire soumis, aucune modification ne peut être
        effectuée.
      </p>
      <div>
        <label htmlFor="affaire">Affaire :</label>
        <select
          id="affaire"
          value={selectedAffaire}
          onChange={handleAffaireChange}
        >
          <option value="">Sélectionnez une valeur</option>
          {missionsData.map((mission, index) => (
            <option key={index} value={mission.Affaire}>
              {mission.Affaire}
            </option>
          ))}
        </select>
      </div>
      {selectedAffaire && (
        <>
          <div>
            <label htmlFor="facturation">Facturation :</label>
            <select
              id="facturation"
              value={selectedFacturation}
              onChange={handleFacturationChange}
            >
              <option value="default" disabled>
                Sélectionnez une valeur
              </option>
              <option value="forfait">Forfait</option>
              <option value="regie">Régie</option>
            </select>
          </div>
          <div>
            <label htmlFor="typeMission">Type de mission :</label>
            <select
              id="typeMission"
              value={selectedTypeMission}
              onChange={handleTypeMissionChange}
            >
              <option value="" disabled>
                Sélectionnez une valeur
              </option>
              <option value="interne">Interne</option>
              <option value="externe">Externe</option>
              <option value="mixte">Mixte</option>
            </select>
          </div>
          <div className="checkBox">
            <p>
              <b>Domaine :</b>
            </p>
            <div className="checkbox-group">
              <label>
                <input
                  type="checkbox"
                  value="DW"
                  checked={selectedDomaines.includes("DW")}
                  onChange={handleDomaineChange}
                />
                DW
              </label>
              <label>
                <input
                  type="checkbox"
                  value="C&I"
                  checked={selectedDomaines.includes("C&I")}
                  onChange={handleDomaineChange}
                />
                C&I
              </label>
              <label>
                <input
                  type="checkbox"
                  value="CS"
                  checked={selectedDomaines.includes("CS")}
                  onChange={handleDomaineChange}
                />
                CS
              </label>
              <label>
                <input
                  type="checkbox"
                  value="EN"
                  checked={selectedDomaines.includes("EN")}
                  onChange={handleDomaineChange}
                />
                EN
              </label>
            </div>
          </div>
        </>
      )}
      {selectedDomaines.map((domaine) => (
        <div key={domaine}>
          <label
            htmlFor={`percentage_${domaine}`}
          >{`${domaine} : Pourcentage sur le chiffre d'affaire de la mission`}</label>
          <input
            type="number"
            min={0}
            max={100}
            id={`percentage_${domaine}`}
            onChange={(event) =>
              handleDomaineDetailsChange(
                domaine,
                "percentage",
                event.target.value
              )
            }
            style={{
              border: totalPercentageError ? "3px solid red" : "",
            }}
          />
          {totalPercentageError && (
            <p style={{ color: "red", marginTop: "4px", marginBottom: "15px" }}>
              {totalPercentageError}
            </p>
          )}
          <label
            htmlFor={`offrePrincipale_${domaine}`}
          >{`${domaine} : Offre Principale`}</label>
          <select
            id={`offrePrincipale_${domaine}`}
            defaultValue="default"
            onChange={(event) =>
              handleDomaineDetailsChange(
                domaine,
                "offrePrincipale",
                event.target.value
              )
            }
          >
            <option value="default" disabled>
              Sélectionnez une valeur
            </option>
            {getBlockOptionsForDomaine(domaine).map((Offre) => (
              <option key={Offre} value={Offre}>
                {Offre}
              </option>
            ))}
          </select>
          {domaineDetails.find(
            (detail) => detail.domaine === domaine && detail.offrePrincipale
          ) && (
            <>
              <label
                htmlFor={`percentageOffrePrincipale_${domaine}`}
              >{`${domaine} : Pourcentage sur l'offre principale`}</label>
              <input
                type="number"
                min={0}
                max={100}
                id={`percentageOffrePrincipale_${domaine}`}
                onChange={(event) =>
                  handleDomaineDetailsChange(
                    domaine,
                    "percentageOffrePrincipale",
                    event.target.value
                  )
                }
                style={{
                  border: subPercentageError ? "3px solid red" : "",
                }}
              />
              {subPercentageError && (
                <p
                  style={{
                    color: "red",
                    marginTop: "4px",
                    marginBottom: "15px",
                  }}
                >
                  {subPercentageError}
                </p>
              )}
              <label
                htmlFor={`offreSecondaire_${domaine}`}
              >{`${domaine} : Offre secondaire`}</label>
              <select
                id={`offreSecondaire_${domaine}`}
                defaultValue="default"
                onChange={(event) =>
                  handleDomaineDetailsChange(
                    domaine,
                    "offreSecondaire",
                    event.target.value
                  )
                }
              >
                <option value="default">
                  Sélectionnez une valeur (optionnel)
                </option>
                {getBlockOptionsForDomaine(domaine).map((Offre) => (
                  <option key={Offre} value={Offre}>
                    {Offre}
                  </option>
                ))}
              </select>
              {domaineDetails.find(
                (detail) => detail.domaine === domaine && detail.offreSecondaire
              ) && (
                <>
                  <label
                    htmlFor={`percentageOffreSecondaire_${domaine}`}
                  >{`${domaine} : Pourcentage sur l'offre secondaire`}</label>
                  <input
                    type="number"
                    min={0}
                    max={100}
                    id={`percentageOffreSecondaire_${domaine}`}
                    onChange={(event) =>
                      handleDomaineDetailsChange(
                        domaine,
                        "percentageOffreSecondaire",
                        event.target.value
                      )
                    }
                    style={{
                      border: subPercentageError ? "3px solid red" : "",
                    }}
                  />
                  {subPercentageError && (
                    <p
                      style={{
                        color: "red",
                        marginTop: "4px",
                        marginBottom: "15px",
                      }}
                    >
                      {subPercentageError}
                    </p>
                  )}
                </>
              )}
            </>
          )}
        </div>
      ))}
      <button type="submit">Valider</button>
    </form>
  );
};

export default NewMissionForm;
